import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from '../../../components/LinkCard/List';
import ListItem from '../../../components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "system-props",
      "style": {
        "position": "relative"
      }
    }}>{`System props`}</h2>
    <p>{`Developer experience will always be one of our focus points. While our initial
implementation for component extension was based around using the Box component
and its clone prop, this updates will alleviate some of the frustrations that
approach brought.`}</p>
    <p>{`From now on, all components can be extended simply by adding the necessary props
directly on the component itself. No more importing the Box component just for
adding some margin around a TextField!`}</p>
    <List mdxType="List">
  <ListItem title="System Props" to="/getting-started/styled-system" description="Find out more about the new system props" mb={8} mdxType="ListItem" />
    </List>
    <h2 {...{
      "id": "more-components",
      "style": {
        "position": "relative"
      }
    }}>{`More components`}</h2>
    <p>{`We're almost at the end of our baseline components, and this release adds
another set of useful components for you to use.`}<List my={8} items={[{
        title: 'Banner',
        subtitle: '',
        to: `/components/banner/code`
      }, {
        title: 'Stepper',
        subtitle: '',
        to: `/components/stepper/code`
      }, {
        title: 'List',
        subtitle: '',
        to: `/components/list/code`
      }]} mdxType="List" /></p>
    <List mb={8} mdxType="List">
  <ListItem title="v0.7.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>
    <h2 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}>{`Looking forward`}</h2>
    <p>{`Every release, we'll try to give you an insight on our plans for the next
release. That way you know what to expect, and see how you can adapt to our new
features.`}</p>
    <p>{`Next release(s) will focus on finishing the base set of building blocks that are
considered widely used components. These include but are not limited to
Autocomplete, Accordion, ...`}</p>
    <p>{`When the base set of building blocks is complete, we'll start looking toward
releasing our first really stable release, denoted by the 1.0.0 version. This
will include some breaking changes (e.g. deprecated tokens). As with all
breaking changes, we'll alway try to indicate it as deprecated for a while,
after which it will be effectively removed in the next major release.`}</p>
    <p>{`We're also looking at a more generic Text component, to more easily resonate
your implementation with how a designer designed their visuals. We'll be
exploring all options in order to bring the two worlds even more together.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      